var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var QI;$CLJS.OI=function(a){return $CLJS.n($CLJS.qB($CLJS.rd,$CLJS.qd,$CLJS.Xk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.PI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Il,Un,zr,S_,Mv){return function(){for(;;){var Nv=
$CLJS.y(gd);if(Nv){if($CLJS.vd(Nv)){var Ov=$CLJS.ic(Nv),rz=$CLJS.D(Ov),Ek=$CLJS.le(rz);a:for(var Ar=0;;)if(Ar<rz){var ht=$CLJS.hd(Ov,Ar);ht=$CLJS.E.g(Mv,$CLJS.ZD)||$CLJS.E.g(Un,$CLJS.ZD)||$CLJS.AE(Mv,ht)&&$CLJS.AE(Un,ht);Ek.add(ht);Ar+=1}else{Ov=!0;break a}return Ov?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Nv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Nv);return $CLJS.ae($CLJS.E.g(Mv,$CLJS.ZD)||$CLJS.E.g(Un,$CLJS.ZD)||$CLJS.AE(Mv,Ek)&&$CLJS.AE(Un,Ek),Rh($CLJS.Hc(Nv)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.kE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.OI($CLJS.MD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.OI($CLJS.MD(a)))}())};
QI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Ne.j($CLJS.wE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.OD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.hl,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.PI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.RI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.SI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var TI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr],null)),UI=null,VI=0,WI=0;;)if(WI<VI){var Kia=UI.X(null,WI);$CLJS.zE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));WI+=1}else{var XI=$CLJS.y(TI);if(XI){var YI=XI;if($CLJS.vd(YI)){var ZI=$CLJS.ic(YI),Lia=$CLJS.jc(YI),
Mia=ZI,Nia=$CLJS.D(ZI);TI=Lia;UI=Mia;VI=Nia}else{var Oia=$CLJS.z(YI);$CLJS.zE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));TI=$CLJS.B(YI);UI=null;VI=0}WI=0}else break}$CLJS.xE($CLJS.fr,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));
for(var $I=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.BF],null)),aJ=null,bJ=0,cJ=0;;)if(cJ<bJ){var Pia=aJ.X(null,cJ);$CLJS.zE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)],null)],null)]));cJ+=1}else{var dJ=$CLJS.y($I);if(dJ){var eJ=dJ;if($CLJS.vd(eJ)){var fJ=$CLJS.ic(eJ),Qia=$CLJS.jc(eJ),
Ria=fJ,Sia=$CLJS.D(fJ);$I=Qia;aJ=Ria;bJ=Sia}else{var Tia=$CLJS.z(eJ);$CLJS.zE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)],null)],null)]));$I=$CLJS.B(eJ);aJ=null;bJ=0}cJ=0}else break}
for(var gJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq],null)),hJ=null,iJ=0,jJ=0;;)if(jJ<iJ){var Uia=hJ.X(null,jJ);$CLJS.vE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));jJ+=1}else{var kJ=$CLJS.y(gJ);if(kJ){var lJ=kJ;if($CLJS.vd(lJ)){var mJ=$CLJS.ic(lJ),Via=$CLJS.jc(lJ),Wia=mJ,Xia=$CLJS.D(mJ);gJ=Via;hJ=Wia;iJ=Xia}else{var Yia=
$CLJS.z(lJ);$CLJS.vE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));gJ=$CLJS.B(lJ);hJ=null;iJ=0}jJ=0}else break}
$CLJS.vE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.ME,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));
$CLJS.vE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.SE,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));
for(var nJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,$CLJS.XE],null)),oJ=null,pJ=0,qJ=0;;)if(qJ<pJ){var Zia=oJ.X(null,qJ);$CLJS.xE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));qJ+=1}else{var rJ=$CLJS.y(nJ);if(rJ){var sJ=rJ;if($CLJS.vd(sJ)){var tJ=$CLJS.ic(sJ),$ia=$CLJS.jc(sJ),aja=tJ,bja=$CLJS.D(tJ);nJ=$ia;oJ=aja;pJ=bja}else{var cja=$CLJS.z(sJ);$CLJS.xE(cja,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));nJ=$CLJS.B(sJ);
oJ=null;pJ=0}qJ=0}else break}
for(var uJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,$CLJS.UE],null)),vJ=null,wJ=0,xJ=0;;)if(xJ<wJ){var dja=vJ.X(null,xJ);$CLJS.xE(dja,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));xJ+=1}else{var yJ=$CLJS.y(uJ);if(yJ){var zJ=yJ;if($CLJS.vd(zJ)){var AJ=$CLJS.ic(zJ),eja=$CLJS.jc(zJ),fja=AJ,gja=$CLJS.D(AJ);uJ=eja;vJ=fja;wJ=gja}else{var hja=$CLJS.z(zJ);$CLJS.xE(hja,$CLJS.H([$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));uJ=$CLJS.B(zJ);
vJ=null;wJ=0}xJ=0}else break}
for(var BJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null),CJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bF,$CLJS.vF,$CLJS.jF,$CLJS.cF],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var GJ=DJ.X(null,FJ);$CLJS.KF.v(GJ,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,GJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,BJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null));FJ+=1}else{var HJ=$CLJS.y(CJ);if(HJ){var IJ=HJ;if($CLJS.vd(IJ)){var JJ=$CLJS.ic(IJ),ija=$CLJS.jc(IJ),jja=JJ,kja=$CLJS.D(JJ);CJ=ija;DJ=jja;EJ=kja}else{var KJ=$CLJS.z(IJ);$CLJS.KF.v(KJ,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,KJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,BJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.gE],null)],null));CJ=$CLJS.B(IJ);DJ=null;EJ=0}FJ=0}else break}
$CLJS.KF.v($CLJS.dB,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.dB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.RE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bG],null)],null));$CLJS.KF.v($CLJS.IE,$CLJS.Zr,$CLJS.ej,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.IE],null),$CLJS.vD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.hD,$CLJS.qD],null)],null));
$CLJS.Y($CLJS.yF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.RI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ar,$CLJS.Gq,$CLJS.BF,$CLJS.SE,$CLJS.ME,$CLJS.Dq,$CLJS.zq,$CLJS.Fq,$CLJS.Bq,$CLJS.LE,$CLJS.XE,$CLJS.NE,$CLJS.UE,$CLJS.jF,$CLJS.cF,$CLJS.bF,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SI,$CLJS.Ai],null)],null));