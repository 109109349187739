import type { OptionsType } from "./types";
import { getUrlProtocol } from "./url";

export function formatImage(
  value: string,
  {
    jsx,
    rich,
    view_as = "auto",
    cellHeight,
    cellImageSize = "cover",
    link_text,
  }: OptionsType = {},
) {
  const url = String(value);
  const protocol = getUrlProtocol(url);
  const acceptedProtocol = protocol === "http:" || protocol === "https:" || protocol === "data:";
  if (jsx && rich && view_as === "image" && acceptedProtocol) {
    return (
      <div
        style={{
          backgroundImage: `url(${url})`,
          backgroundSize: cellImageSize,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: cellHeight || 30,
          width: "100%",
        }}
      ></div>
    );
  } else {
    return url;
  }
}
