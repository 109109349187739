var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var wY,zoa,Aoa,Boa,Coa,DY,Doa,Eoa,EY,Foa,GY,Goa,Hoa,Ioa,LY,MY,Joa,Koa,TY,Loa,Moa,Noa,YY,Ooa;wY=function(){};$CLJS.xY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(wY,a):$CLJS.Ya(wY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.yY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.zY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.AY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.BY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.CY=new $CLJS.M(null,"search","search",1564939822);DY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
EY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.FY=new $CLJS.M(null,"dataset","dataset",1159262238);GY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.HY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.IY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.JY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.KY=new $CLJS.M("source","joins","source/joins",1225821486);
LY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);MY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.NY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.OY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.PY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.QY=new $CLJS.M(null,"active","active",1895962068);$CLJS.RY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.SY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);TY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);
Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.UY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.VY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.WY=new $CLJS.M("source","native","source/native",-1444604147);Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);
$CLJS.XY=new $CLJS.M(null,"auto-list","auto-list",314197731);YY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.ZY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.$Y=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.aZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(LY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ar,$CLJS.PY,$CLJS.WY,$CLJS.zY,$CLJS.BY,$CLJS.UY,$CLJS.lK,$CLJS.dK,$CLJS.KY,$CLJS.HY,$CLJS.JY],null));$CLJS.Y(EY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.XY,null,$CLJS.CY,null,$CLJS.Yi,null,$CLJS.cz,null],null),null))));
$CLJS.Y(TY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.kK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.zD],null)],null));
$CLJS.Y(DY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.fK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.TJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.MJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,$CLJS.nj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ki,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.AD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Y,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.WJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.h(null,2,[$CLJS.ni,1,$CLJS.tj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.VY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DY],null)],null)],
null)],null));$CLJS.Y(YY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null)],null)],null)],null));
$CLJS.Y(GY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
$CLJS.qD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.jK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.WJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AY,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.sj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.sj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,GY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.iD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.sj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.gD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.sj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.hK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.sj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.sj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.aZ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Ai],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Di,$CLJS.Ai],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.sj],null)],null)],null));
$CLJS.Y(MY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.xY},Coa,$CLJS.zg([$CLJS.zi,$CLJS.U,$CLJS.xj,$CLJS.di,$CLJS.wi,$CLJS.Ni,$CLJS.Ej,$CLJS.ci,$CLJS.uj,$CLJS.wj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ep],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.xY)?$CLJS.xY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MY],null)],null)],null)],null));