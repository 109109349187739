import type * as React from "react";
import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { usePrevious, useMount } from "react-use";
import type { OnScrollParams } from "react-virtualized";
import { Grid, Collection, ScrollSync, AutoSizer } from "react-virtualized";
import { t } from "ttag";
import _ from "underscore";

import { sumArray } from "metabase/lib/arrays";
import {
  COLUMN_SHOW_TOTALS,
  isPivotGroupColumn,
  multiLevelPivot,
} from "metabase/lib/data_grid";
import { getScrollBarSize } from "metabase/lib/dom";
import { getSetting } from "metabase/selectors/settings";
import {
  getDefaultSize,
  getMinSize,
} from "metabase/visualizations/shared/utils/sizes";
import { getColumnKey } from "metabase-lib/queries/utils/get-column-key";
import type { DatasetData, VisualizationSettings } from "metabase-types/api";
import type { State } from "metabase-types/store";

import {
  PivotTableRoot,
  PivotTableTopLeftCellsContainer,
} from "./PivotTable.styled";
import type {
  TLineBreak} from "./PivotTableCell";
import {
  Cell,
  TopHeaderCell,
  LeftHeaderCell,
  BodyCell
} from "./PivotTableCell";
import { RowToggleIcon } from "./RowToggleIcon";
import {
  DEFAULT_CELL_WIDTH,
  LEFT_HEADER_LEFT_SPACING,
  MIN_HEADER_CELL_WIDTH,
  DEFAULT_CELL_PADDING,
} from "./constants";
import {
  settings,
  _columnSettings as columnSettings,
  getTitleForColumn,
} from "./settings";
import type { PivotTableClicked, HeaderWidthType } from "./types";
import {
  getLeftHeaderWidths,
  isSensible,
  checkRenderable,
  leftHeaderCellSizeAndPositionGetter,
  topHeaderCellSizeAndPositionGetter,
  getCellWidthsForSection,
} from "./utils";

const mapStateToProps = (state: State) => ({
  fontFamily: getSetting(state, "application-font"),
});

interface RenderCell {
  rowIndex: number;
  columnIndex: number;
  key: string;
  style: React.CSSProperties;
}

interface PivotTableProps {
  data: DatasetData;
  settings: VisualizationSettings;
  width: number;
  onUpdateVisualizationSettings: (settings: VisualizationSettings) => void;
  isNightMode: boolean;
  isDashboard: boolean;
  fontFamily?: string;
  onVisualizationClick: (options: any) => void;
}

function PivotTable({
  data,
  settings,
  width,
  onUpdateVisualizationSettings,
  isNightMode,
  isDashboard,
  fontFamily,
  onVisualizationClick,
}: PivotTableProps) {
  const { rows } = data
  const [gridElement, setGridElement] = useState<HTMLElement | null>(null);
  const columnWidthSettings = settings["pivot_table.column_widths"];

  const isTitleAutoHeight = settings["pivot_table.title_auto_height"];

  const titleIndentVertical = settings["pivot_table.title_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.titile_indent_vertical"];
  const titleIndentHorizontal = settings["pivot_table.title_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.title_indent_left"];
  const titleHeight = isTitleAutoHeight
    ? settings["pivot_table.title_font_size"] + 1 + titleIndentVertical * 2
    : settings["pivot_table.title_height"];

  const cellIndentVertical = settings["pivot_table.cell_auto_indent"]
    ? DEFAULT_CELL_PADDING
    : settings["pivot_table.cell_indent_vertical"];
  const cellSettingHeight = settings["pivot_table.cell_auto_height"]
    ? settings["pivot_table.cell_font_size"] + cellIndentVertical * 2
    : settings["pivot_table.cell_height"];

  const maxValueOfLinesInCell = ()=>{
    const columnSettings = settings["column_settings"]
    let showLineBreak = false
    let maxCount = 1

    for (const key in columnSettings) {
      const columnSettingsItem = columnSettings[key]
      for (const key in columnSettingsItem) {
        if (columnSettingsItem[key] === 'show') {
          showLineBreak = true
        }
      }
    }

    if (showLineBreak) {
      rows.forEach((row)=>{
        row.forEach((cell)=>{
          if (typeof (cell) === 'string') {
            const count = cell.split('\n').length;
            if (count>maxCount) {
              maxCount=count
            }
          }
        })
      })
      return {maxCount}
    }
    return {maxCount}
  }

  const cellHeight = cellSettingHeight*maxValueOfLinesInCell().maxCount;

  const cellFontSize = settings["pivot_table.cell_font_size"];
  const cellRowToggleIconSize = cellFontSize / 2.5;
  const cellFontStyle = settings["pivot_table.cell_font_italic"]
    ? "italic"
    : "normal";
  const cellFontStyles: React.CSSProperties = {
    fontSize: cellFontSize,
    fontStyle: cellFontStyle,
    fontWeight: "bold",
  };

  const titleFontSize = settings["pivot_table.title_font_size"];
  const titleRowToggleIconSize = titleFontSize / 2;

  const titleFontStyle = settings["pivot_table.title_font_italic"]
    ? "italic"
    : "normal";
  const titleFontWeight = settings["pivot_table.title_font_bold"]
    ? "bold"
    : "normal";

  const leftHeaderCellBackgroundColor =
    settings["pivot_table.header_background_color"];
  const leftHeaderTextColor = settings["pivot_table.header_text_color"];
  const leftHeaderBackgroundOnHover =
    settings["pivot_table.header_background_color_hover"];
  const isLeftHeaderTransparent = settings["pivot_table.header_transparent"];

  const topHeaderTextHorizontalAlignment =
    settings["pivot_table.header_horizontal_alignment"];
  const topHeaderTextVerticalAlignment =
    settings["pivot_table.title_vertical_alignment"];

  const isPivotTablePinMode = settings["pivot_table.pin_mode"];
  const pinnedRowsCountSettings = settings["pivot_table.pinned_rows_count"];
  const pinnedColumnsCountSettings =
    settings["pivot_table.pinned_columns_count"];

  const leftHeaderBorderColor = settings["pivot_table.grid_color"];

  const titleFontStyles: React.CSSProperties = {
    fontSize: titleFontSize,
    fontStyle: titleFontStyle,
    fontWeight: titleFontWeight,
  };

  const [
    { leftHeaderWidths, totalLeftHeaderWidths, valueHeaderWidths },
    setHeaderWidths,
  ] = useState<HeaderWidthType>({
    leftHeaderWidths: null,
    totalLeftHeaderWidths: null,
    valueHeaderWidths: {},
    ...(columnWidthSettings ?? {}),
  });

  const [clickedRow, setClickedRow] = useState<number>(-1);

  const updateHeaderWidths = useCallback(
    (newHeaderWidths: Partial<HeaderWidthType>) => {
      setHeaderWidths(prevHeaderWidths => ({
        ...prevHeaderWidths,
        ...newHeaderWidths,
      }));

      onUpdateVisualizationSettings({
        "pivot_table.column_widths": {
          leftHeaderWidths,
          totalLeftHeaderWidths,
          valueHeaderWidths,
          ...newHeaderWidths,
        },
      });
    },
    [
      onUpdateVisualizationSettings,
      leftHeaderWidths,
      totalLeftHeaderWidths,
      valueHeaderWidths,
    ],
  );

  const bodyRef = useRef(null);
  const pinnedBodyRef = useRef(null);
  const leftHeaderRef = useRef(null);
  const topHeaderRef = useRef(null);

  const getColumnTitle = useCallback(
    function (columnIndex: number) {
      const column = data.cols.filter(col => !isPivotGroupColumn(col))[
        columnIndex
      ];
      return getTitleForColumn(column, settings);
    },
    [data, settings],
  );

  function isColumnCollapsible(columnIndex: number) {
    const columns = data.cols.filter(col => !isPivotGroupColumn(col));
    const { [COLUMN_SHOW_TOTALS]: showTotals } = settings.column(
      columns[columnIndex],
    );
    return showTotals;
  }

  const getColumnLineBreak = (index: number) => {
    const cols = data && data.cols;
    let defaultValue: TLineBreak = "hidden";
    if (!cols) {
      return defaultValue;
    }
    const column = leftHeaderItems[index].column
    if (!column) {
      return defaultValue;
    }
    const columnKey = getColumnKey(column)
    for (const key in settings["column_settings"]) {
      const columnSettingsLineBreak = settings["column_settings"][key]["column_line_break"]
      key === columnKey && columnSettingsLineBreak === "show" ? defaultValue = "show" : null
    }
    return defaultValue
  }

  useEffect(() => {
    // This is needed in case the cell counts didn't change, but the data or cell sizes did
    (
      leftHeaderRef.current as Collection | null
    )?.recomputeCellSizesAndPositions?.();
    (
      topHeaderRef.current as Collection | null
    )?.recomputeCellSizesAndPositions?.();
    (bodyRef.current as Grid | null)?.recomputeGridSize?.();
    (pinnedBodyRef.current as Grid | null)?.recomputeGridSize?.();
  }, [
    data,
    leftHeaderRef,
    topHeaderRef,
    leftHeaderWidths,
    valueHeaderWidths,
    pinnedBodyRef,
  ]);

  useMount(() => {
    setGridElement(bodyRef.current && findDOMNode(bodyRef.current));
  });

  const pivoted = useMemo(() => {
    if (data == null || !data.cols.some(isPivotGroupColumn)) {
      return null;
    }

    try {
      return multiLevelPivot(data, settings);
    } catch (e) {
      console.warn(e);
    }
    return null;
  }, [data, settings]);

  const previousRowIndexes = usePrevious(pivoted?.rowIndexes);
  const hasColumnWidths = [
    leftHeaderWidths,
    totalLeftHeaderWidths,
    valueHeaderWidths,
  ].every(Boolean);
  const columnsChanged =
    !hasColumnWidths ||
    (previousRowIndexes && !_.isEqual(pivoted?.rowIndexes, previousRowIndexes));

  // In cases where there are horizontal scrollbars are visible AND the data grid has to scroll vertically as well,
  // the left sidebar and the main grid can get out of ScrollSync due to slightly differing heights
  function scrollBarOffsetSize() {
    if (!gridElement) {
      return 0;
    }
    // get the size of the scrollbars
    const scrollBarSize = getScrollBarSize();
    const scrollsHorizontally =
      gridElement.scrollWidth > parseInt(gridElement.style.width);

    if (scrollsHorizontally && scrollBarSize > 0) {
      return scrollBarSize;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (!pivoted?.rowIndexes) {
      setHeaderWidths({
        leftHeaderWidths: null,
        totalLeftHeaderWidths: null,
        valueHeaderWidths,
      });
      return;
    }

    if (columnsChanged) {
      const newLeftHeaderWidths = getLeftHeaderWidths({
        rowIndexes: pivoted?.rowIndexes,
        getColumnTitle: idx => getColumnTitle(idx),
        leftHeaderItems: pivoted?.leftHeaderItems,
        fontFamily: fontFamily,
      });

      setHeaderWidths({ ...newLeftHeaderWidths, valueHeaderWidths });

      onUpdateVisualizationSettings({
        "pivot_table.column_widths": {
          ...newLeftHeaderWidths,
          valueHeaderWidths,
        },
      });
      onUpdateVisualizationSettings({
        "pivot_table.column_widths": {
          ...getLeftHeaderWidths({
            rowIndexes: pivoted?.rowIndexes,
            getColumnTitle: idx => getColumnTitle(idx),
            leftHeaderItems: pivoted?.leftHeaderItems,
            fontFamily: fontFamily,
          }),
          valueHeaderWidths: {},
        },
      });
    }
  }, [
    valueHeaderWidths,
    onUpdateVisualizationSettings,
    pivoted,
    fontFamily,
    getColumnTitle,
    columnsChanged,
    setHeaderWidths,
  ]);

  const handleColumnResize = (
    columnType: "value" | "leftHeader",
    columnIndex: number,
    newWidth: number,
  ) => {
    let newColumnWidths: Partial<HeaderWidthType> = {};

    if (columnType === "leftHeader") {
      const newLeftHeaderColumnWidths = [...(leftHeaderWidths as number[])];
      newLeftHeaderColumnWidths[columnIndex] = Math.max(
        newWidth,
        MIN_HEADER_CELL_WIDTH,
      );

      const newTotalWidth = sumArray(newLeftHeaderColumnWidths);

      newColumnWidths = {
        leftHeaderWidths: newLeftHeaderColumnWidths,
        totalLeftHeaderWidths: newTotalWidth,
      };
    } else if (columnType === "value") {
      const newValueHeaderWidths = { ...(valueHeaderWidths ?? {}) };
      newValueHeaderWidths[columnIndex] = Math.max(
        newWidth,
        MIN_HEADER_CELL_WIDTH,
      );

      newColumnWidths = {
        valueHeaderWidths: newValueHeaderWidths,
      };
    }

    updateHeaderWidths(newColumnWidths);
  };

  if (pivoted === null || !leftHeaderWidths || columnsChanged) {
    return null;
  }

  const {
    leftHeaderItems,
    topHeaderItems,
    rowCount,
    columnCount,
    rowIndex,
    getRowSection,
    getLeftSection,
    rowIndexes,
    columnIndexes,
    valueIndexes,
  } = pivoted;

  const maxIndexOfRowsInHeader = topHeaderItems.reduce(
    (maxDepth, item) => Math.max(maxDepth, item.depth),
    0,
  );

  const topHeaderRows =
    columnIndexes.length + (valueIndexes.length > 1 ? 1 : 0) || 1;

  const topHeaderHeight = topHeaderRows * titleHeight;

  const leftHeaderWidth =
    rowIndexes.length > 0
      ? LEFT_HEADER_LEFT_SPACING + (totalLeftHeaderWidths ?? 0)
      : 0;

  function getCellClickHandler(
    clicked: PivotTableClicked | undefined,
    rowIndex: number | undefined,
  ) {
    return (e: React.SyntheticEvent) => {
      if (rowIndex || rowIndex === 0) {
        setClickedRow(rowIndex);
      }

      if (clicked) {
        onVisualizationClick({
          ...clicked,
          event: e.nativeEvent,
          settings,
        });
      }
    };
  }

  const pinnedRowsCount =
    pinnedRowsCountSettings > rowCount
      ? rowCount
      : pinnedRowsCountSettings < 1
      ? 1
      : pinnedRowsCountSettings;
  const pinnedColumnsCount =
    pinnedColumnsCountSettings > columnCount
      ? columnCount
      : pinnedColumnsCountSettings < 1
      ? 1
      : pinnedColumnsCountSettings;
  const getSubColumnWidths = (index: number) => {
    const subColumnWidths = getCellWidthsForSection(
      valueHeaderWidths,
      valueIndexes,
      index,
    );
    return sumArray(subColumnWidths);
  };

  let pinnedColumnsWidth: number = 0;
  for (let i = 0; i < pinnedColumnsCount; i++) {
    pinnedColumnsWidth += getSubColumnWidths(i);
  }
  const maxPinnedColumnsLeftScroll =
    pinnedColumnsWidth - width + leftHeaderWidth + scrollBarOffsetSize();

  const getPinnedTableHeight = (height: number) =>
    height - cellHeight - scrollBarOffsetSize();
  const getMaxPinnedTopScroll = (height: number) =>
    cellHeight * pinnedRowsCount - getPinnedTableHeight(height);

  const renderCell = ({ rowIndex, columnIndex, key, style }: RenderCell) => (
    <BodyCell
      key={key}
      style={{
        ...style,
        ...cellFontStyles,
        pointerEvents: "all",
      }}
      rowSection={getRowSection(columnIndex, rowIndex)}
      isNightMode={isNightMode}
      getCellClickHandler={getCellClickHandler}
      rowIndex={rowIndex}
      clickedRow={clickedRow}
      settings={settings}
      cellWidths={getCellWidthsForSection(
        valueHeaderWidths,
        valueIndexes,
        columnIndex,
      )}
    />
  );

  return (
    <PivotTableRoot
      isDashboard={isDashboard}
      isNightMode={isNightMode}
      data-testid="pivot-table"
    >
      <ScrollSync>
        {({ onScroll, scrollLeft, scrollTop }) => (
          <div className="full-height flex flex-column">
            <div className="flex" style={{ height: topHeaderHeight }}>
              {/* top left corner - displays left header columns */}
              <PivotTableTopLeftCellsContainer
                isNightMode={isNightMode}
                style={{
                  width: leftHeaderWidth,
                  height: topHeaderHeight,
                  ...titleFontStyles,
                }}
              >
                {rowIndexes.map((rowIndex: number, index: number) => (
                  <Cell
                    key={rowIndex}
                    isEmphasized
                    isBold
                    isBorderedHeader
                    isTransparent={isLeftHeaderTransparent}
                    backgroundColor={leftHeaderCellBackgroundColor}
                    textColor={leftHeaderTextColor}
                    backgroundColorOnHover={leftHeaderBackgroundOnHover}
                    hasTopBorder={topHeaderRows > 1}
                    isNightMode={isNightMode}
                    value={getColumnTitle(rowIndex)}
                    onResize={(newWidth: number) =>
                      handleColumnResize("leftHeader", index, newWidth)
                    }
                    style={{
                      display: "flex",
                      flex: "0 0 auto",
                      width:
                        (leftHeaderWidths?.[index] ?? 0) +
                        (index === 0 ? LEFT_HEADER_LEFT_SPACING : 0),
                      ...(index === rowIndexes.length - 1
                        ? { borderRight: "none" }
                        : {}),
                      height: topHeaderHeight,
                      alignItems: topHeaderTextVerticalAlignment,
                      justifyContent: topHeaderTextHorizontalAlignment,
                      paddingTop: titleIndentVertical,
                      paddingLeft: titleIndentHorizontal,
                    }}
                    borderColor={leftHeaderBorderColor}
                    icon={
                      // you can only collapse before the last column
                      index < rowIndexes.length - 1 &&
                      isColumnCollapsible(rowIndex) && (
                        <RowToggleIcon
                          size={titleRowToggleIconSize}
                          value={index + 1}
                          settings={settings}
                          updateSettings={onUpdateVisualizationSettings}
                        />
                      )
                    }
                    lineBreak="hidden"
                  />
                ))}
              </PivotTableTopLeftCellsContainer>
              {/* top header */}
              <Collection
                ref={topHeaderRef}
                className="scroll-hide-all"
                isNightMode={isNightMode}
                width={width - leftHeaderWidth}
                height={topHeaderHeight}
                cellCount={topHeaderItems.length}
                cellRenderer={({ index, style, key }) => (
                  <TopHeaderCell
                    key={key}
                    style={{
                      ...style,
                      ...titleFontStyles,
                      display: "flex",
                      paddingTop: titleIndentVertical,
                      paddingLeft: titleIndentHorizontal,
                    }}
                    settings={settings}
                    item={topHeaderItems[index]}
                    getCellClickHandler={getCellClickHandler}
                    isNightMode={isNightMode}
                    onResize={(newWidth: number) =>
                      handleColumnResize(
                        "value",
                        topHeaderItems[index].offset,
                        newWidth,
                      )
                    }
                  />
                )}
                cellSizeAndPositionGetter={({ index }) =>
                  topHeaderCellSizeAndPositionGetter(
                    topHeaderItems[index],
                    topHeaderRows,
                    valueHeaderWidths,
                    topHeaderHeight / (maxIndexOfRowsInHeader + 1),
                  )
                }
                onScroll={({ scrollLeft }) =>
                  onScroll({ scrollLeft } as OnScrollParams)
                }
                scrollLeft={scrollLeft}
              />
            </div>
            <div className="flex flex-full">
              {/* left header */}
              <div style={{ width: leftHeaderWidth }}>
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <Collection
                      ref={leftHeaderRef}
                      className="scroll-hide-all"
                      cellCount={leftHeaderItems.length}
                      //
                      cellRenderer={({ index, style, key}) => (
                        <LeftHeaderCell
                          key={key}
                          style={{ ...style, ...cellFontStyles }}
                          cellData={getLeftSection(index)}
                          rowIndexes={rowIndex}
                          onUpdateVisualizationSettings={
                            onUpdateVisualizationSettings
                          }
                          settings={settings}
                          isNightMode={isNightMode}
                          getCellClickHandler={getCellClickHandler}
                          rowToggleIconSize={cellRowToggleIconSize}
                          lineBreak={getColumnLineBreak(index)}
                        />
                      )}
                      cellSizeAndPositionGetter={({ index }) =>
                        leftHeaderCellSizeAndPositionGetter(
                          leftHeaderItems[index],
                          leftHeaderWidths ?? [0],
                          rowIndexes,
                          cellHeight,
                        )
                      }
                      width={leftHeaderWidth}
                      height={height - scrollBarOffsetSize()}
                      scrollTop={scrollTop}
                      onScroll={({ scrollTop }) =>
                        onScroll({ scrollTop } as OnScrollParams)
                      }
                    />
                  )}
                </AutoSizer>
              </div>
              {/* pivot table body */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <>
                      {isPivotTablePinMode && (
                        <Grid
                          style={{
                            zIndex: 2,
                            pointerEvents: "none",
                            overflow: "hidden",
                            left: 0,
                            right: 0,
                            position: "absolute",
                          }}
                          width={
                            width - leftHeaderWidth - scrollBarOffsetSize()
                          }
                          height={getPinnedTableHeight(height)}
                          className="text-dark scrollbar-transparent scroll-hide-all"
                          rowCount={pinnedRowsCount}
                          columnCount={pinnedColumnsCount}
                          rowHeight={cellHeight}
                          columnWidth={({ index }) => getSubColumnWidths(index)}
                          estimatedColumnSize={DEFAULT_CELL_WIDTH}
                          cellRenderer={renderCell}
                          ref={pinnedBodyRef}
                          scrollTop={
                            scrollTop > getMaxPinnedTopScroll(height)
                              ? getMaxPinnedTopScroll(height)
                              : scrollTop
                          }
                          scrollLeft={
                            scrollLeft > maxPinnedColumnsLeftScroll
                              ? maxPinnedColumnsLeftScroll
                              : scrollLeft
                          }
                        />
                      )}

                      <Grid
                        style={{
                          zIndex: 1,
                          left: 0,
                          right: 0,
                          position: "absolute",
                        }}
                        width={width - leftHeaderWidth}
                        height={height}
                        className="text-dark scrollbar-transparent"
                        rowCount={rowCount}
                        columnCount={columnCount}
                        rowHeight={cellHeight}
                        columnWidth={({ index }) => getSubColumnWidths(index)}
                        estimatedColumnSize={DEFAULT_CELL_WIDTH}
                        cellRenderer={renderCell}
                        onScroll={({ scrollLeft, scrollTop }) =>
                          onScroll({ scrollLeft, scrollTop } as OnScrollParams)
                        }
                        ref={bodyRef}
                        scrollTop={scrollTop}
                        scrollLeft={scrollLeft}
                      />
                    </>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        )}
      </ScrollSync>
    </PivotTableRoot>
  );
}

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default Object.assign(connect(mapStateToProps)(PivotTable), {
  uiName: t`Pivot Table`,
  identifier: "pivot",
  iconName: "pivot_table",
  minSize: getMinSize("pivot"),
  defaultSize: getDefaultSize("pivot"),
  canSavePng: false,
  isSensible,
  checkRenderable,
  settings,
  columnSettings,
  isLiveResizable: () => false,
});

export { PivotTable };
